.TenantRegistartionContainer {
  padding: 100px;
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%; */
  /* padding: 20px;
  max-width: 600px;
  margin: auto; */
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.SaasForm {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.moduleCheckboxContainer {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: column;
}

.moduleCheckboxContainer div {
  display: flex;
  align-items: center;
  justify-content: left;
}

.moduleCheckBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.moduleIcon {
  width: 50px;
  height: auto;
}
