

.tbody {
  display: flex;
  flex-direction: column;
}

.ProductTableDiv {
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .ProductTableDiv table {
}

quotation_form_div {
 
} */

.quotation_form {
  padding: 5px;
  width: 100%;
  height: 100%;
  /* background-color: #0056b3; */
}

.form-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-label {
  margin-bottom: 10px;
  width: 100%;
}

.form-label select,
.form-label input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.product-table-div {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
}

.product-table {
  width: 100%;
  border: 1;
  /* border-collapse: collapse; */
}

/* .product-table th,
.product-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
} */

.product-table th {
  background-color: #f2f2f2;
}

.submit-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.QuotationActionBtnsDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.QuotaionAmountDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items:flex-end;
  justify-content: right;
}
