.NewWarehouseForm {
  width: 100%;
}

.NewWarehouseLabel {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.addbtn {
  width: 100%;
}
