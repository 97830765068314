* {
  margin: 0;
  padding: 0;
}

.landing {
  width: 100vw;
  height: 100vh;
  /* background-color: brown; */
  display: flex;
  flex-direction: column;
}

.AppBarDiv {
  width: 100%;
  height: 7%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1; /* Ensure it's above other content */
  background-color: aqua;
}

.landingDisplay {
  /* padding-top: 10%; Matches the height of AppBarDiv */
  margin-top: 5%;
  padding: 0; /* Ensure no padding */
  width: 100%;
  /* height: auto; */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto; /* Enable scrolling if content overflows */
  background-color: whitesmoke;
}

.AppsContainer {
  /* box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-around;
  align-content: center; */
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Responsive columns */
  gap: 20px; /* Space between cards */
  /* max-width: 1200px;  */
  /* Max width of the container */
  max-width: 100%;
  justify-content: center;
  align-content: center;
}

/* .AppsContainer {
  width: 100%;
  height: 90%;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 5px;
  align-items: center;
  justify-content: space-evenly;
} */

.AppCard {
  /* width: 150px;
  height: 150px;
  background-color: #dee9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 2px 4px #fffbec; */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  width: 200px;
  height: 200px;
  padding: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.AppCard img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  /* margin-bottom: 20px; */
}

.AppCard h3 {
  margin: 0;
  color: #333;
  font-size: 1.5rem;
}
