.usermg {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 93vh;
}

.userMngHeadervDiv {
  width: 100%;
  height: 5%;
  background-color: aqua;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.userMngActionDiv {
  width: 100%;
  height: 5%;
  background-color: aqua;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* .inventoryDisplay {
  width: 100%;
  height: 100%;
  background-color: antiquewhite;
} */

.addbtn {
  background-color: blue;
  color: white;
}
