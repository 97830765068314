.settings {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.settingNav {
  width: 10%;
  height: 100%;
  left: 0;
  background-color: #333;
  padding-top: 20px;
}

.settingDisplay {
  width: 90%;
  overflow-y: scroll;
  padding: 5px;
}

/* .SettingGridDvi {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 50px;
  overflow-x: scroll;
  scroll-behavior: smooth;
} */

/* .setting {
  width: 300px;
  height: 300px;
  background-color: lightblue;
} */

.icon1 {
  background-image: url("../../assest/Setting/circle.png");
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.settingNav ul li {
  padding: 10px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.settingNav ul li:hover {
  background-color: #555;
}

.icon {
  display: inline-block;
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 10px;
  vertical-align: middle;
}
