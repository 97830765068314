.UserDepartment {
  width: 100%;
  height: 81vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
  background-color: lightgray;
}

.departmentsContainer {
  width: 100%;
  height: auto;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-gap: 150px;
  grid-template-columns: auto auto auto;
}

.departmentCard {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  background-color: white;
  border-left: 10px solid blue;
}

.departmentCard .quantyty {
  background-color: blueviolet;
  color: white;
  width:fit-content;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}
