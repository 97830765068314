.profile {
  /* display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.profileContainer {
  /* display: flex;
  flex-direction: column;
  width: 100%; */
  width: 80%;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
}

.profilecontent {
  display: flex;
  /* flex-direction: column;
  width: 100%; */
}

.profilecontent1 {
  /* width: 100%;
  align-items: center;
  justify-content: center;
  display: flex; */
  flex: 1;
}

.profilecontent2 {
  /* width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
  flex: 2;
}

.image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.image-container img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.edit-button {
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
