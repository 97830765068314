.signIn {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url("../../../assest/bgimg1.jpg");
  background-size: contain; */
  background-color: #f0f2f5;
}

.signIn_Form_div {
  /* width: 20%;
  max-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  box-shadow: 2px 4px gray;
  border-radius: 15px; */
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

/* .signin_container {
  background-color: whitesmoke;
  box-shadow: 2px 4px gray;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.signin_company {
  /* max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  text-align: center;
  margin-bottom: 20px;
}

.signin_company h4 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.users_signin_form {
  /* max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; */
  display: flex;
  flex-direction: column;
}

.users_signin_form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-size: 14px;
  color: #333;
}

.emailSignin {
  padding: 10px;
  border: 2px solid #0a4dea;
  border-radius: 5px;
  margin-top: 5px;
}

.passwordinput {
  padding: 10px;
  border: 2px solid #0a4dea;
  border-radius: 5px;
  margin-top: 5px;
}

.sif2 {
  border-top-color: black;
  border-top-style: dashed;
}

.logo {
  /* max-width: 100%;
  height: 150px;
  margin-bottom: 5px; */
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.loginbtn {
  /* max-width: 100%;
  color: white;
  background-color: blue;
  margin-top: 5px; */
  background-color: #1890ff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.loginbtn:hover {
  background-color: #40a9ff;
}

.Sigin_footer {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #aaa;
}

.sif2 h5 {
  font-size: 12px;
  font-weight: normal;
  color: #aaa;
}

.signinbtn_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sif1 h4 {
  color: blue;
  font-weight: bold;
}
