.Profile_Edit {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ChangePassword {
  width: 50%;
}
