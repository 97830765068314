.inventory {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  flex-grow: 1;
}
.inventorynavDiv {
  width: 100%;
  height: 15%;
  background-color: aqua;
  display: flex;
  flex-direction: column;
}

/* .inventorySubMenuDev {
  width: 50%;
  height: 75%;
  /* display: flex;
  flex-direction: row;
  justify-content: space-evenly; 
} 

/* .inventorySubMenuDev ul {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
} */

/* .inventoryAction {
  width: 100%;
  height: 5%;
  background-color: aqua;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
} */
.inventoryDisplay {
  width: 100%;
  height: 90%;
  background-color: antiquewhite;
}

.inventoty_Menus {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: whitesmoke;
}
