.usermg {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
}

/* .userMngHeadervDiv {
  width: 100%;
  height: 5%;
  background-color: aqua;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
} */
.userMngActionDiv {
  width: 100%;
  height: 20%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.ModuleTitle {
  font-family: "Times New Roman", Times, serif;
  padding: 10px;
  font-size: 32px;
}

/* .inventoryDisplay {
  width: 100%;
  height: 100%;
  background-color: antiquewhite;
} */
/* .newBtn {
  background-color: lightblue;
  color: white;

} */

.UserMng_Menus {
  background-color: #71639e;
}

.UserMng_Menus Button {
  color: white;
}

/* .NewUserForm {
  width: 100%;
  box-sizing: border-box;
} */

/* .AddBtn {
  background-color: lightblue;
  color: white;
  font-weight: bold;
} */
.userDisplay {
  box-sizing: border-box;
  max-width: 100%;
  height: auto;
  flex-grow: 1;
}
