* {
  font-family: Arial, sans-serif;
}
.quotation {
  border: 1px solid #000;
  padding: 20px;
  margin: 20px;
  max-width: 600px;
  width: 100vw;
}
.quotation h2 {
  margin-top: 0;
}
.quotation .customer-info {
  margin-bottom: 20px;
}
.quotation .items {
  border-collapse: collapse;
  width: 100%;
}
.quotation .items th,
.quotation .items td {
  border: 1px solid #000;
  padding: 8px;
}
.header,
.footer {
  text-align: center;
  background-color: #f2f2f2;
  padding: 10px 0;
  width: 100vw;
}
