* {
  font-family: Arial, sans-serif;
}

/* .quotationMain {
  display: block;
} */

/* .quotationMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
} */

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.header img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.header h1 {
  font-size: 24px;
  margin: 0;
}

.header p {
  margin: 5px 0;
}

.quotation {
  border: 1px solid #000;
  padding: 20px;
  margin: 20px;
  /* max-width: 600px;
  width: 100vw; */
  width: 100%;
  flex-grow: 1;
}
.quotation h2 {
  margin-top: 0;
}

.quotationinfo {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.quotation .customer-info {
  margin-bottom: 20px;
}
.quotation .items {
  border-collapse: collapse;
  width: 100%;
}
.quotation .items th,
.quotation .items td {
  border: 1px solid #000;
  padding: 8px;
}

.items th {
  background-color: #f2f2f2;
}

.items tr:nth-child(even) {
  background-color: #f9f9f9;
}

/*  */
.priceDiv {
  border-top: 2px solid #000;
  padding-top: 10px;
  margin-top: 20px;
}

.priceRow {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.priceRow p {
  margin: 0;
}

.total {
  border-top: 1px solid #000;
  padding-top: 10px;
  font-weight: bold;
}
/*  */

.footer {
  text-align: center;
  margin-top: 30px;
}

.footer p {
  margin: 5px 0;
}

/* .header,
.footer {
  text-align: center;
  background-color: #f2f2f2;
  padding: 10px 0;
  width: 100vw;
} */
