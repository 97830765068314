.Sales {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 92vh;
}

.salesnavDiv {
  width: 100%;
  height: 15%;
  background-color: aqua;
  display: flex;
  flex-direction: column;
}
