/* Landing2.css */
.LandingMain {
  font-family: Arial, sans-serif;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  position: relative;
  top: 0;
}

.saashederTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.saasLogoDiv {
  font-size: 24px;
  font-weight: bold;
}

.saasMenuActionDiv {
  flex-grow: 1;
}

/* .App_content{

} */

.Modules {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.Modules div {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Modules img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

footer {
  background-color: #282c34;
  color: white;
  padding: 20px 0;
  /* position: absolute;
  bottom: 0; */
  width: 100%;
}
