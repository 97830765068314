.userMngTableDiv {
  width: 100%;
  background-color: lightblue;
  color: white;
}

.userlabels {
  display: flex;
  flex-direction: column;
  width: max-content;
}

.NewUserModalHeaderDiv {
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
  font-size: 24px;
}

/*  */
.newusermodalLeft {
  width: 50%;
}

.newusermodalRight {
  width: auto;
  padding: 20px;
}

/*  */

.NewUserModalFooterDiv {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  /* background-color: antiquewhite; */
}

.NewUserModalFooterDiv input {
  border-color: blue;
}

.AddBtn {
  background-color: blue;
  color: white;
}

.UserModuleRBAC {
  display: flex;
  flex-direction: column;
}

/* .UserMngDataGrid {

} */

/* New user mod */
.NewUserModalDiv {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

/* .NewUserFormContainer {
  max-width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-around;
} */

/*  */
