.Dashboard {
  width: 100%;
  height: 100%;
}

.cardsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-around;
}

.cardsContainer .Card {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px gray;
  border-radius: 5px;
}

.cardsContainer .Card img{
    width: 100px;
    height: 100px;
  }
