.tbody {
  display: flex;
  flex-direction: column;
}

.ProductTableDiv {
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ProductTableDiv table {
}
